/*
@import 'variables.scss';

@import 'media-queries.scss';

@import 'typography.scss';

@import 'tabs.scss';

@import 'lists.scss';

@import 'common.scss';

@import 'button.scss';

@import 'components/navigation.scss';

@import 'components/footer.scss';

@import 'components/call-to-action.scss';

@import 'components/modal.scss';

@import 'pages/home.scss';

@import 'pages/team.scss';

@import 'pages/blog.scss';

@import 'pages/about.scss';

@import 'pages/404.scss';

@import 'pages/career.scss';

@import 'pages/faq.scss';

@import 'pages/privacy.scss';

@import 'pages/sign-in.scss';

@import 'pages/comming-soon.scss';

@import 'pages/contact.scss';

